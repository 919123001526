var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.feedback)?_c('div',{staticClass:"c-feedback-form print:hidden"},[(!_vm.state)?_c('div',{staticClass:"flex flex-col items-center mx-auto"},[_c('div',{staticClass:"text-button font-semibold uppercase",domProps:{"textContent":_vm._s(_vm.feedback.title)}}),_vm._v(" "),_c('div',{staticClass:"flex justify-center gap-x-12 mt-12"},[_c('button',{class:[
					'w-40 h-40 rounded-full bg-extra',
					'flex justify-center items-center',
					'hover:opacity-80' ],attrs:{"aria-label":"Thumbs up"},on:{"click":_vm.onThumbsUp}},[_c('SvgThumbsUp',{staticClass:"w-16 text-white"})],1),_vm._v(" "),_c('button',{class:[
					'w-40 h-40 rounded-full bg-extra',
					'flex justify-center items-center',
					'hover:opacity-80' ],attrs:{"aria-label":"Thumbs down"},on:{"click":_vm.onThumbsDown}},[_c('SvgThumbsDown',{staticClass:"w-16 text-white"})],1)])]):(!_vm.success)?_c('form',{class:[
			'relative',
			'mx-auto w-5col >=768:w-12col',
			'bg-extra rounded-lg py-md px-sm' ],on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('button',{class:[
				'absolute top-12 right-12',
				'w-40 h-40 rounded-full',
				'flex justify-center items-center' ],on:{"click":_vm.onClear}},[_c('SvgClose',{staticClass:"text-white w-10"})],1),_vm._v(" "),_c('div',{class:[
				'flex flex-col items-center gap-y-sm',
				'>=768:w-6col text-center text-white mx-auto' ]},[_c('div',{class:[
					'w-40 h-40 bg-white rounded-full',
					'flex items-center justify-center',
					'text-extra' ]},[_c(_vm.icon,{tag:"Component",staticClass:"w-16"})],1),_vm._v(" "),_c('BaseH1',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.config.title)}}),_vm._v(" "),_c('p',{domProps:{"textContent":_vm._s(_vm.config.description)}})],1),_vm._v(" "),_c('div',{staticClass:"space-y-md mt-md mx-auto text-white >=768:w-8col"},[(_vm.config.fields.comment)?_c('div',[_c('label',{staticClass:"block mb-12 font-semibold",attrs:{"for":(_vm._uid + "-" + (_vm.config.fields.comment.name))},domProps:{"textContent":_vm._s(_vm.config.fields.comment.label)}}),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.fields.comment.value),expression:"config.fields.comment.value"}],class:[
						'c-feedback-form__textarea',
						'text-h4 py-sm px-md text-text',
						'w-full bg-white rounded-md' ],attrs:{"id":(_vm._uid + "-" + (_vm.config.fields.comment.name)),"name":(_vm._uid + "-" + (_vm.config.fields.comment.name)),"placeholder":_vm.config.fields.comment.placeholder,"required":""},domProps:{"value":(_vm.config.fields.comment.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config.fields.comment, "value", $event.target.value)}}}),_vm._v(" "),_c('div',{staticClass:"text-h7 mt-4 opacity-80",domProps:{"textContent":_vm._s('Påkrævet')}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:">=768:flex gap-x-layout-gutter <768:space-y-md"},[(_vm.config.fields.name)?_c('div',{staticClass:"flex-1"},[_c('label',{staticClass:"block mb-12 font-semibold",attrs:{"for":(_vm._uid + "-" + (_vm.config.fields.name.name))},domProps:{"textContent":_vm._s(_vm.config.fields.name.label)}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.fields.name.value),expression:"config.fields.name.value"}],class:[
							'text-h4 py-sm px-md text-text',
							'w-full bg-white rounded-md' ],attrs:{"id":(_vm._uid + "-" + (_vm.config.fields.name.name)),"type":"text","name":(_vm._uid + "-" + (_vm.config.fields.name.name)),"placeholder":_vm.config.fields.name.placeholder},domProps:{"value":(_vm.config.fields.name.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config.fields.name, "value", $event.target.value)}}})]):_vm._e(),_vm._v(" "),(_vm.config.fields.email)?_c('div',{staticClass:"flex-1"},[_c('label',{staticClass:"block mb-12 font-semibold",attrs:{"for":(_vm._uid + "-" + (_vm.config.fields.email.name))},domProps:{"textContent":_vm._s(_vm.config.fields.email.label)}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.fields.email.value),expression:"config.fields.email.value"}],class:[
							'text-h4 py-sm px-md text-text',
							'w-full bg-white rounded-md' ],attrs:{"id":(_vm._uid + "-" + (_vm.config.fields.email.name)),"type":"email","name":(_vm._uid + "-" + (_vm.config.fields.email.name)),"placeholder":_vm.config.fields.email.placeholder},domProps:{"value":(_vm.config.fields.email.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config.fields.email, "value", $event.target.value)}}})]):_vm._e()]),_vm._v(" "),_c('BaseButton',{class:['mx-auto', { 'opacity-50': _vm.loading }],attrs:{"aria-disabled":_vm.loading},domProps:{"textContent":_vm._s('Send feedback')}})],1)]):_c('div',{class:[
			'mx-auto w-5col >=768:w-12col',
			'bg-extra rounded-lg py-lg px-sm' ]},[_c('div',{class:[
				'flex flex-col items-center gap-y-sm',
				'>=768:w-6col text-center text-white mx-auto' ]},[_c('div',{class:[
					'w-40 h-40 bg-white rounded-full',
					'flex items-center justify-center',
					'text-extra' ]},[_c(_vm.icon,{tag:"Component",staticClass:"w-16"})],1),_vm._v(" "),_c('BaseH1',{attrs:{"tag":"h2"},domProps:{"textContent":_vm._s(_vm.config.success)}})],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }