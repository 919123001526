<template>
	<div v-if="feedback" class="c-feedback-form print:hidden">
		<!-- Thumbs, actually two of them. -->
		<div v-if="!state" class="flex flex-col items-center mx-auto">
			<div
				class="text-button font-semibold uppercase"
				v-text="feedback.title"
			></div>

			<div class="flex justify-center gap-x-12 mt-12">
				<button
					:class="[
						'w-40 h-40 rounded-full bg-extra',
						'flex justify-center items-center',
						'hover:opacity-80',
					]"
					aria-label="Thumbs up"
					@click="onThumbsUp"
				>
					<SvgThumbsUp class="w-16 text-white" />
				</button>

				<button
					:class="[
						'w-40 h-40 rounded-full bg-extra',
						'flex justify-center items-center',
						'hover:opacity-80',
					]"
					aria-label="Thumbs down"
					@click="onThumbsDown"
				>
					<SvgThumbsDown class="w-16 text-white" />
				</button>
			</div>
		</div>

		<!-- Form -->
		<form
			v-else-if="!success"
			:class="[
				'relative',
				'mx-auto w-5col >=768:w-12col',
				'bg-extra rounded-lg py-md px-sm',
			]"
			@submit.prevent="onSubmit"
		>
			<button
				:class="[
					'absolute top-12 right-12',
					'w-40 h-40 rounded-full',
					'flex justify-center items-center',
				]"
				@click="onClear"
			>
				<SvgClose class="text-white w-10" />
			</button>

			<div
				:class="[
					'flex flex-col items-center gap-y-sm',
					'>=768:w-6col text-center text-white mx-auto',
				]"
			>
				<div
					:class="[
						'w-40 h-40 bg-white rounded-full',
						'flex items-center justify-center',
						'text-extra',
					]"
				>
					<Component :is="icon" class="w-16" />
				</div>

				<BaseH1 tag="h2" v-text="config.title" />
				<p v-text="config.description"></p>
			</div>

			<div class="space-y-md mt-md mx-auto text-white >=768:w-8col">
				<div v-if="config.fields.comment">
					<label
						:for="`${_uid}-${config.fields.comment.name}`"
						class="block mb-12 font-semibold"
						v-text="config.fields.comment.label"
					></label>

					<textarea
						:id="`${_uid}-${config.fields.comment.name}`"
						v-model="config.fields.comment.value"
						:name="`${_uid}-${config.fields.comment.name}`"
						:placeholder="config.fields.comment.placeholder"
						:class="[
							'c-feedback-form__textarea',
							'text-h4 py-sm px-md text-text',
							'w-full bg-white rounded-md',
						]"
						required
					></textarea>

					<div
						class="text-h7 mt-4 opacity-80"
						v-text="'Påkrævet'"
					></div>
				</div>

				<div class=">=768:flex gap-x-layout-gutter <768:space-y-md">
					<div v-if="config.fields.name" class="flex-1">
						<label
							:for="`${_uid}-${config.fields.name.name}`"
							class="block mb-12 font-semibold"
							v-text="config.fields.name.label"
						></label>

						<input
							:id="`${_uid}-${config.fields.name.name}`"
							v-model="config.fields.name.value"
							type="text"
							:name="`${_uid}-${config.fields.name.name}`"
							:placeholder="config.fields.name.placeholder"
							:class="[
								'text-h4 py-sm px-md text-text',
								'w-full bg-white rounded-md',
							]"
						/>
					</div>

					<div v-if="config.fields.email" class="flex-1">
						<label
							:for="`${_uid}-${config.fields.email.name}`"
							class="block mb-12 font-semibold"
							v-text="config.fields.email.label"
						></label>

						<input
							:id="`${_uid}-${config.fields.email.name}`"
							v-model="config.fields.email.value"
							type="email"
							:name="`${_uid}-${config.fields.email.name}`"
							:placeholder="config.fields.email.placeholder"
							:class="[
								'text-h4 py-sm px-md text-text',
								'w-full bg-white rounded-md',
							]"
						/>
					</div>
				</div>

				<BaseButton
					:aria-disabled="loading"
					:class="['mx-auto', { 'opacity-50': loading }]"
					v-text="'Send feedback'"
				/>
			</div>
		</form>

		<!-- Succes -->
		<div
			v-else
			:class="[
				'mx-auto w-5col >=768:w-12col',
				'bg-extra rounded-lg py-lg px-sm',
			]"
		>
			<div
				:class="[
					'flex flex-col items-center gap-y-sm',
					'>=768:w-6col text-center text-white mx-auto',
				]"
			>
				<div
					:class="[
						'w-40 h-40 bg-white rounded-full',
						'flex items-center justify-center',
						'text-extra',
					]"
				>
					<Component :is="icon" class="w-16" />
				</div>

				<BaseH1 tag="h2" v-text="config.success" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

import SvgThumbsDown from '~/assets/svgs/thumbs-down.svg?inline';
import SvgThumbsUp from '~/assets/svgs/thumbs-up.svg?inline';
import SvgClose from '~/assets/svgs/close-icon.svg?inline';

export default {
	name: 'FeedbackForm',

	components: {
		SvgThumbsDown,
		SvgThumbsUp,
		SvgClose,
	},
	inject: ['pageKey'],

	data() {
		return {
			icon: null,
			state: null,
			config: null,
			token: null,
			error: null,
			loading: false,
			success: false,
		};
	},

	computed: mapState({
		feedback: (state) => state.site?.feedback,
	}),

	methods: {
		onThumbsUp() {
			this.icon = 'SvgThumbsUp';
			this.state = this.feedback.positiveResponse;
			this.config = this.formatConfig(this.feedback.positive);
			!this.token && this.submitRating();
		},

		onThumbsDown() {
			this.icon = 'SvgThumbsDown';
			this.state = this.feedback.negativeResponse;
			this.config = this.formatConfig(this.feedback.negative);
		},

		onClear() {
			this.icon = null;
			this.state = null;
			this.config = null;
		},

		onSubmit() {
			this.error = null;
			this[this.token ? 'updateComment' : 'submitComment']?.();
		},

		submitRating() {
			if (this.state !== this.feedback.negativeResponse) {
				this.$axios
					.post(this.feedback.ratingEndpoint, {
						rating: this.state,
						siteKey: this.feedback.siteKey,
						pageKey: this.pageKey,
					})
					.then((response) => {
						this.token = response.data.key;
					})
					.catch((error) => {
						this.error = error;
						console.error(error);
					});
			}
		},

		updateComment() {
			if (this.token) {
				this.loading = true;

				this.$axios
					.post(
						this.feedback.updateEndpoint.replace(
							'{key}',
							this.token
						),
						{
							rating: this.state,
							siteKey: this.feedback.siteKey,
							pageKey: this.pageKey,
							comment: this.config.fields.comment.value,
							name: this.config.fields.name.value,
							email: this.config.fields.email.value,
						}
					)
					.then(() => {
						this.loading = false;
						this.success = true;
					})
					.catch((error) => {
						this.error = error;
						console.error(error);
					});
			}
		},

		submitComment() {
			this.loading = true;

			this.$axios
				.post(this.feedback.commentEndpoint, {
					rating: this.state,
					siteKey: this.feedback.siteKey,
					pageKey: this.pageKey,
					comment: this.config.fields.comment.value,
					name: this.config.fields.name.value,
					email: this.config.fields.email.value,
				})
				.then(() => {
					this.loading = false;
					this.success = true;
				})
				.catch((error) => {
					this.error = error;
					console.error(error);
				});
		},

		/**
		 * Helper functions
		 */
		formatConfig(config) {
			const fields = {};
			config.fields?.forEach((field) => {
				fields[field.name] = {
					...field,
					value: '',
				};
			});

			return {
				...config,
				fields,
			};
		},
	},
};
</script>

<style lang="postcss">
.c-feedback-form__textarea {
	min-height: 160px;
}
</style>
